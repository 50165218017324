/* ********************
Set root URL for LESS compiling
-------------------- */
@base-url: "/wp-content/themes/puur-fit/";

/* ********************
Imports
******************** */
@import "fonts.less";
@import (less) "vendor/bootstrap-grid.min.css";

/* ********************
General 
******************** */
body {
	margin: 0;
	font-family: 'WorkSans-Regular', sans-serif;
	font-size: 1.6rem;
	font-weight: normal;
	background: #FFFFFF;
	-webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    
    &:before {
	    position: fixed;
	    content: "";
	    left: 0;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    background: @dark-grey;
	    opacity: 0;
	    visibility: hidden;
	    z-index: 888;
	    transition: all .2s ease-in-out;
    }
    
    &.show-overlay {
	    &:before {
		    opacity: 0.3;
		    visibility: visible;
	    }
    }
    
    &.modal-overlay {
	    &:before {
		    opacity: 0.8;
		    z-index: 9998;
		    visibility: visible;
	    }
    }
}

@media only screen and (max-width: 640px) {
body {
	margin-top: 6rem;
}
}

li {
	list-style-type: none;
}

html { 
	margin-top: 0 !important;
	font-size: 62.5%; 
}

.base-grid {
	position: relative;
	z-index: 10;
	max-width: 144rem;
	margin: 0 auto;
	padding: 0 2.4rem;
}

.editor {
	
	h1,h2,h3,h4,h5,h6 {
		font-family: 'WorkSans-Regular', sans-serif;
		font-weight: normal;
		margin: 0;
		color: @dark-grey;
	}
	
	strong {
		font-family: 'WorkSans-Medium', sans-serif;
		font-weight: normal;
		color: fade(@dark-grey, 80%);
	}
	
	h1 {
		font-size: 3rem;
		line-height: 4.2rem;
		margin-bottom: 1.6rem;
	}
	
	h2 {
		font-size: 2.8rem;
		line-height: 4rem;
		margin-bottom: 0.8rem;
	}
	
	p {
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: @paragraph;
		margin: 0; 
		
		a {
			color: @blue;
		}
		
		img {
		    max-width: 100%;
			height: auto;
		}
	}  
	
	iframe {
		width: 100%;
	}
	
	ul {
		margin: 0;
		padding: 0;
		margin-bottom: 2.4rem;
			
		li {
			position: relative;
			padding: 0.4rem 0 0.4rem 3.6rem;
			color: @paragraph;
			line-height: 2.4rem;
			
			&:before {
				position: absolute;
				content:"";
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background-image: url('@{base-url}/assets/img/checkmark.svg');
			    background-repeat: no-repeat;
			    background-position: center center;
			    background-size: 2rem;
			    width: 2rem;
			    height: 1.2rem;
			}
		}
	}
	
	ol {
		margin: 0;
		padding: 0;
			
		li {
			position: relative;
			padding: 0.4rem 0 0.4rem 3.6rem;
			color: @paragraph;
			line-height: 2.4rem;
			
			&:before {
				position: absolute;
				content:"";
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background-image: url('@{base-url}/assets/img/checkmark.svg');
			    background-repeat: no-repeat;
			    background-position: center center;
			    background-size: 2rem;
			    width: 2rem;
			    height: 1.2rem;
			}
		}
	}
	
	.medium-18 {
		font-family: 'WorkSans-Medium', sans-serif;
		font-size: 1.8rem;
		line-height: 2.8rem;
		margin-bottom: 0.8rem;
	}
} 

@media only screen and (max-width: 640px) {
.editor {
	h2 {
		font-size: 2.2rem;
		line-height: 3.4rem;
	}
}
}
    
/* Colors */
@blue: 				#36A9E1;  
@light-grey: 		#EDEFF1;
@dark-grey: 		#353535;
@paragraph: 		#747474;
@tile-bg: 			#F4F5F7;

/* ********************
Coming soon
******************** */
.coming-soon {
	background: @blue;
	color: white;
	height: 100vh;
	padding: 0 2rem;
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		
		h1 { 
			color: white;
			margin-bottom: 1.6rem; 
			font-weight: 600;
			font-size: 5.6rem;
		}
		
		p { 
			color: white;
			opacity: 0.7; 
			margin: 0; 
		}
 
	}

}  

@media only screen and (max-width: 640px) {
.coming-soon {
	margin-top: -6rem;
	
	&__wrapper {
		align-items: flex-start;

		h1 { 
			font-size: 3.8rem;
			line-height: 1;
		}
	}
}
}

/* ********************
Page header
******************** */
.page-header {
	z-index: 999;
	position: relative; 
	
	&__top {
		background: @light-grey;
		height: 6rem;
		
		.base-grid {
			height: 100%;
		}
	}
	
	&__nav { 
		background: @dark-grey;
		overflow: hidden;
	}
}

@media only screen and (max-width: 991px) {
.page-header {
	top: 0;
	position: fixed;
    width: 100%;
    background: white;
	height: 6rem;
	z-index: 999;
	border-bottom: 0.1rem solid @light-grey;
	    
	&__top {
		display: none;
	}
	
	&__nav {
		position: fixed;
	    top: 6rem;
	    left: 0;
	    width: 100%;
	    height: ~"calc(100% - 6rem)";
	    transform: translateX(100%);
	    transition: all .2s ease-in-out;
	    background: @light-grey;
	    
	    .base-grid {
		    padding: 0;
	    }
	    
	    &--active {
		    transform: translateX(0);
	    }
	}
}
}

.mainnav {
	> ul {
		display: flex; 
		flex-direction: row;
		justify-content: center;
		padding: 0;
		margin: 0;
		
		> li {
			position: relative;
			
			&.current-menu-item {
				> a {
					opacity: 1;
				}
			}
			
			&.btn-kennismaken {
				position: absolute;
			    right: 0;
			    background: @blue;
			    top: 0;
			    transition: all .2s ease-in-out;
			    
			    a { 
				    color: white;
			    }
			    
			    &:hover {
				    background: darken(@blue, 10%);
			    }
			    
			    &:after {
				    position: absolute;
				    content:"";
				    right: 0;
				    top: 0;
				    width: 100rem;
				    transform: translate(100rem);
				    background: @blue;
				    height: 100%;
			    }
			}
			
			> a {
				position: relative;
				font-family: 'WorkSans-Medium', sans-serif;
				display: block;
				text-decoration: none;
				color: white;
				font-size: 1.5rem; 
				font-weight: 500;
				padding: 1.6rem 2rem;
				opacity: 0.8;
				transition: all .2s ease-in-out; 
				
				&:hover {
					opacity: 1;
				}
			} 
			
			> ul {
				position: absolute;
			    left: 0;
			    background: #ffffff;
			    padding: 0;
			    opacity: 0;
			    visibility: hidden;
			    transform: translateY(0.6rem);
			    transition: all .2s ease-in-out;
			    
				> li {
					padding: 0; 
					
					> a {
						display: block;
						color: #707070; 
						font-size: 1.5rem; 
						text-decoration: none;
					    padding: 24px;
					    border-bottom: 1px solid #f1efed;
					    transition: all .2s ease-in-out;
					    
					    &:hover {
						    background: @light-grey;
					    }
					}
					
					&:last-child {
						> a {
							border-bottom: 0;
						}
					}
				}
			}
			
			&:hover {
				> ul {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
				}
			}
		}
	}
}

.subnav-arrow {
	cursor: pointer;
    padding-right: 16px !important;
    background: url('@{base-url}/assets/img/nav-chevron-down.svg');
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center right 15px;
}

@media only screen and (max-width: 991px) {
.subnav-arrow {
	padding-right: 0 !important;
	background: url('@{base-url}/assets/img/nav-chevron-down-grey.svg');
	background-size: 10px;
    background-repeat: no-repeat;
    background-position: top 26px right 24px;
}
}

@media only screen and (max-width: 1024px) {
.mainnav {
	> ul {
		justify-content: flex-start;
		padding-left: 10rem;
	}
}
}

@media only screen and (max-width: 991px) {
.mainnav {
	> ul {
		flex-direction: column;
		padding-left: 0rem;
		
		> li { 
			border-bottom: 0.1rem solid rgba(53, 53, 53, 0.1); 
			
			&.btn-kennismaken {
			    position: relative;
			    right: unset;
			    width: ~"calc(100% - 32px)";
			    left: 16px;
			    text-align: center;
		        position: absolute;
			    bottom: 0;
			    top: unset;
			    transform: translateY(100%); 
			    
			    &:after {
				    display: none;
			    }
			}
			
			> a {
				color: @dark-grey; 
			    font-size: 1.6rem;
				padding: 2rem;
			}
			
			&:first-child {
				display: none;
			}
			
			> ul {
				display: none;
				position: relative;
			}
			
			&:hover {
				> ul {
					display: block;
				}
			}
		}
	}
}
}

.topnav {
	height: 100%;
	
	> ul {
		display: flex; 
		flex-direction: row;
		justify-content: flex-end;
		height: 100%;
		align-items: center;
		padding: 0;
		margin: 0;
		
		> li {
			
			> a {
				position: relative;
				display: block;
				font-size: 1.3rem;
				color: #4A4A4A;
				text-decoration: none;
				padding: 0;
				opacity: 0.8;
				padding-left: 2.4rem;
				
				&:hover {
					transition: all .2s ease-in-out; 					
					opacity: 1;
				}
			}  
			
			&:first-child {
				a {		
					&:before {
						position: absolute;
						content:"";
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						background-image: url('@{base-url}/assets/img/phone-icon.svg');
					    background-repeat: no-repeat;
					    background-position: center center;
				        background-size: 13px;
					    width: 1.3rem;
					    height: 1.3rem;
					}
				}
			}
			
			&:last-child {
				padding-left: 3.2rem;
				
				a {				
					&:before {
						position: absolute;
						content:"";
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						background-image: url('@{base-url}/assets/img/mail-icon.svg');
					    background-repeat: no-repeat;
					    background-position: center center;
				        background-size: 13px;
					    width: 1.3rem;
					    height: 1rem;
					}
				}
			}

		}
	}
}

@media only screen and (max-width: 991px) {
.topnav {
	> ul {
		flex-direction: column;
		align-items: flex-start;
		margin: 1.6rem 0;
		
		> li {	
			width: 100%;
					
			> a {
				width: 100%;
				padding: 0.8rem 2rem 0.8rem 4.8rem;
				font-size: 1.4rem;
				
				&:before {
					left: 2rem;
				}
			}
			
			&:first-child {
				> a {
					&:before {
						left: 2rem;
					}
				}
			}
			
			&:last-child {
				padding: 0;
				
				> a { 
					&:before {
						left: 2rem;
					}
				}
			}
		}
	}
}
}

.nav-house {
	background-image: url('@{base-url}/assets/img/house-icon.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px;
    opacity: 0.8;
    transition: opacity .2s ease-in-out;
    
    > a {
	    text-indent: -100rem;
    }
    
    &:hover {
	    opacity: 1;
    }
}


/* ********************
Page logo
******************** */
.page-logo {
	position: absolute;
	top: 1rem;
	
	svg {
		width: 8.8rem;
		height: 8.8rem;
	}
}

@media only screen and (max-width: 991px) {
.page-logo {
	top: 1.6rem;
	left: 50%;
    transform: translateX(-50%);
    
    svg {
        width: 8rem;
		height: 8rem;
    }
}
}

/* ********************
Mobile toggle
******************** */
.mobile-toggle,
.mobile-phone {
	display: none;
}

@media only screen and (max-width: 991px) {
.mobile-toggle {
    position: absolute;
	display: block;
	width: 6rem;
    height: 6rem;
    background: @light-grey;
    right: 0;
    top: -0.1rem;
    
    span {
	    display: block;
		position: absolute;
	    height: 0.2rem;
		background: @dark-grey;
		width: 1.8rem;
		top: 2.4rem;
		left: 50%; 
		transform: translateX(-50%);
		
		&:nth-child(2) {
			top: 2.9rem;
		}
		
		&:nth-child(3) {
			top: 3.4rem;
		}
    }
}

.mobile-phone {
	display: block;
	position: absolute;
	left: 0;
	top: -0.1rem;
	width: 6rem; 
	height: 6rem;
	background: @blue;
	display: flex;
    align-items: center;
    justify-content: center;
	
	svg {
		width: 1.6rem;
		height: 1.6rem;
	}
}
}

/* ********************
Page intro
******************** */
.page-intro {
	position: relative;
	height: 56rem;
	overflow: hidden; 
	
	.base-grid {
		display: flex;
		align-items: center;
	    height: 100%;
	    top: 0;
	    left: 0;
	}
	
	&__image { 
		position: absolute;
	    top: 0;
	    right: 0;
	    width: 80%;
	    height: 100%;
	    
		img {
			width: 100%;
			height: 100%;
			object-position: 50% 50%;
			object-fit: cover;
		}
		
		&--align-top {
			img {
				object-position: top; 
			}
		}
		
		&--align-middle {
			img {
				object-position: center; 
			}
		}
		
		&--align-bottom {
			img {
				object-position: bottom; 
			}
		}
		
		&--overlay {
			&:before {
				position: absolute;
				content:"";
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: #14668A;
				opacity: 0.7;
			}
		}
		
		&--full {
			width: 100%;
		}
	}
	
	
	&__gradient {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(90deg, rgba(54,169,225,1) 0%, rgba(54,169,225,1) 20%, rgba(54,169,225,0) 100%);
		z-index: 5;
		
		&:before {
			position: absolute;
			content:"";
			left: -100rem;
			top: 0;
			background: @blue;
			width: 100rem;
			height: 100%;
		}
	}
	
	&__content {
		position: relative;
		z-index: 10;
		max-width: 60%;
		
		h1 {
			font-family: 'WorkSans-Medium', sans-serif;
			color: white;
			font-size: 4.6rem;
			line-height: 5.8rem;
			margin: 0;
			margin-bottom: 0.8rem;
			
		}
		
		p {
			color: white;
			font-size: 1.7rem;
		    line-height: 3.2rem;
		    opacity: 0.8;
		    margin: 0;
		    margin-bottom: 2.4rem;
		}
		
		ol {
		    display: flex;
		    flex-direction: row;
		    flex-wrap: wrap;
		    padding: 0;
		    margin-left: -8px;
			
			li {
				color: white;
				opacity: 0.8;
				flex-basis: 50%;
				max-width: 50%;
				padding: 0.8rem;
			}
		}
		
		&--center {
			text-align: center;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
			
			p {
				max-width: 69rem;
			    font-size: 1.6rem;
				line-height: 2.8rem;
				opacity: 1;
			}
		}
		
		&--full {
			max-width: 100%;
			width: 100%;
		}
	}
	
	&--small {
		height: 40rem;
		
		.page-intro__image {
		    width: 100%;
		    left: 0;
		    right: inherit;
		}
	} 
}

@media only screen and (max-width: 991px) {
.page-intro {
	height: 48rem;
	
	&__content {
		text-align: center;
		max-width: 100%;
		
		p {
			br {
				display: none;
			}
		}
	}
}
}

@media only screen and (max-width: 640px) {
.page-intro {
	height: 36rem;
	
	.base-grid {
	    padding: 0 1.6rem;
	}
	
	&__content {
		
		p {
			margin-bottom: 0;
			font-size: 1.4rem;
			line-height: 2rem;
		}
		
		h1 {
		    font-size: 3rem;
			line-height: 4rem;
		}
	}
	
	&--small {
		height: 18rem;
	}
}
}

.scroll-down {
	svg {
		width: 2.2rem;
	}
}

@media only screen and (max-width: 640px) {
.scroll-down {
	display: none;
}
}

/* ********************
Section
******************** */
.section {
	padding: 8.8rem 0;
	
	&--appointments {
		padding: 0;
		margin-top: -6.4rem;
		margin-bottom: 10.4rem;
	}
	
	&--grey-bg {
		background: @light-grey;
	}
	
	&--divider {
		padding: 0.8rem 0;
	}
	
	&--flex { 
		
		.editor { 
/*
			h2 {
				font-family: 'WorkSans-Medium', sans-serif;
				font-size: 1.8rem;
				line-height: 2.8rem;
			}
*/
			
			p {
				margin-bottom: 2.4rem;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
		} 
	}
		
	&--flex-grey {
		background: @light-grey;
		padding: 8rem 0;
		
		&.section--divider {
			padding: 0;
			height: 2.4rem;
			
			.divider {
				&:after {
					background: @light-grey;
				}
			}
		} 
	}
	
	&--usps {
		padding: 1.6rem 0;
		background: @light-grey;
	}
}

@media only screen and (max-width: 1199px) {
.section {
	&--appointments {
		margin-bottom: 8rem;
	}
}
}

@media only screen and (max-width: 991px) {
.section {
	padding: 6.4rem 0;
	
	&--divider {
		padding: 0.8rem 0;
	}
	
	&--usps {
		padding: 4rem 0;
	}
	&--appointments {
		padding: 0;
		margin-bottom: 6.4rem;
	}
}
}

@media only screen and (max-width: 640px) {
.section {
	padding: 4rem 0;
	
	&--usps {
		padding: 1.6rem 0;
	}
	
	&--divider {
		padding: 0.8rem 0;
	}
	
	&--appointments {
		padding: 0;
		margin-bottom: 4rem;
		margin-top: -3.2rem;
	}
}
}

/* ********************
Introduction
******************** */
.introduction {
	&__title {
		font-family: 'WorkSans-Regular', sans-serif;
		font-size: 2.8rem;
		line-height: 4rem;
		margin-bottom: 0.8rem;
	}
	
	.link {
		margin-top: 2.4rem;
	}
}


/* ********************
Link
******************** */
.link {
	font-family: 'WorkSans-Medium', sans-serif;
	font-size: 1.6rem;
	line-height: 2.8rem;
	text-decoration: none;
	transition: all .2s ease-in-out;
	
	&--blue {
		color: @blue;	
		
		&:hover {
			color: darken(@blue, 10%);
		}	
	}
	
	&--underline {
		text-decoration: underline;
	}
	
	&--arrow {
		position: relative;
	    display: inline-flex;
		overflow: hidden;
		align-items: center;
		padding-right: 4rem;
		
		span {
			transform: translateX(0);
			transition: all .2s ease-in-out;
		}
		
		.arrow-front {
			transform: translateX(-100%) translateY(-50%);
		}
		
		.arrow-back {
			transform: translateY(-50%) translateX(0);
			margin-left: 1.6rem;
			right: 0; 
		}
		
		svg {
			position: absolute;
			width: 1.9rem;  
			top: 50%;
			transform: translateY(-50%);
			transition: all .2s ease-in-out;
		} 
		
		&:hover {
			.arrow-back {
				transform: translateY(-50%) translateX(100%);
			}
			.arrow-front {
				transform: translateY(-50%) translateX(0);
			}
			
			span {
				transform: translateX(4rem);
			}
		}
	}
}

/* ********************
Home blocks
******************** */
.home-blocks {
	&__intro {
		margin-bottom: 5.6rem; 
	}
}

@media only screen and (max-width: 991px) {
.home-blocks {
	&__intro {
		margin-bottom: 4rem;
		
		p {
			br {
				display: none;
			}
		}
	}
}
}

/* ********************
Image tile
******************** */
.image-tile {
	display: flex;
	flex-direction: row;
	margin-bottom: 4rem;
	border-radius: 0.4rem;
	overflow: hidden;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	&:nth-child(even) {
		.image-tile__column--image {
			order: 2;
		}
	}
	
	&__image {
	    position: relative;
	    overflow: hidden;
	    width: 100%;
	    height: 100%;
	    
		img {
		    object-fit: cover;
		    width: 100%;
		    height: 100%;
		    left: 0;
		    top: 0;
		    position: absolute;
		    z-index: 10;
		}
	}
	
	&__column {
		flex-basis: 50%;
		max-width: 50%;
		
		h3 {
			margin-bottom: 0.8rem;
		}
		
		p {
			margin-bottom: 2.4rem;
		}
		
		ul {
			margin-bottom: 2.4rem;
			
			li {
				position: relative;
				padding: 0.4rem 0 0.4rem 3.6rem;
				color: @paragraph;
				line-height: 2.4rem;
				
				&:before {
					position: absolute;
					content:"";
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					background-image: url('@{base-url}/assets/img/checkmark.svg');
				    background-repeat: no-repeat;
				    background-position: center center;
				    background-size: 2rem;
				    width: 2rem;
				    height: 1.2rem;
				}
			}
		}
		
		&--white-bg {
			background: white;
			padding: 6.4rem 5.6rem;
		}
	}
}

@media only screen and (max-width: 991px) {
.image-tile {
	flex-direction: column;
	margin-bottom: 2.4rem;
	
	&__column {
		flex-basis: 100%;
		max-width: 100%;
		
		&--white-bg {
			padding: 4rem;
			order: 2;
		}
	}
	
	&__image {
		order: 1;
		height: 24rem;
	}
}
}

@media only screen and (max-width: 640px) {
.image-tile {
	&__column {
		&--white-bg {
			padding: 3.2rem;
		}
	}
	
	&__image {
		height: 18rem;
	}
}
}

/* ********************
Button
******************** */
.button {
	display: inline-flex;
    align-items: center;
    justify-content: center;
	font-family: 'WorkSans-Medium', sans-serif;
	font-size: 1.6rem;
	line-height: 2.4rem;
	text-decoration: none;
	min-height: 4.8rem; 
    padding: 1.2rem 3.2rem;
	cursor: pointer;
	transition: all .2s ease-in-out;
	
	&--blue {
		background: @blue;
		color: white !important;
		
		&:hover {
			background: darken(@blue, 10%);
		}
	}
	
	&--black {
		background: @dark-grey;
		color: white;
		margin-top: 1.6rem;
		border: 0;
		
		&:hover {
			background: darken(@dark-grey, 10%);
		}
	}
	
	&--facebook {
		padding-left: 2.4rem;
		    
		svg {
			height: 1.7rem;
			margin-right: 2.4rem;
		}
	}
}

@media only screen and (max-width: 640px) {
.button {
	width: 100%;
}
}

/* ********************
Tile
******************** */
.category-tiles {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 5.6rem;
	width: 100%;
	justify-content: center;
}

@media only screen and (max-width: 991px) {
.category-tiles {
	padding-top: 4rem;
}
}

.event-tiles {
	margin-top: 4rem;
}

@media only screen and (max-width: 991px) {
.event-tiles {
	margin-top: 4rem;
}
}

@media only screen and (max-width: 640px) {
.event-tiles {
	width: 100%;
    margin-left: 0;
    margin-right: 0;
}
}
 

.tile {
	display: block;
	padding: 0 1.6rem;
	text-decoration: none;
	transform: translateY(0);
	transition: all .2s ease-in-out;
	
	&__inner {
		position: relative;
		display: flex;
		flex-direction: column;
		background: @tile-bg;
		border-radius: 0.4rem;
		overflow: hidden; 
		text-decoration: none;
		
		&--no-radius {
			border-radius: 0;
		}
	}
	
	&__price-wrapper {
		background: rgba(45, 170, 255, 0.1);
	}
	
	&__content {
		padding: 4rem 3.2rem; 
	}
	
	&__title {
		font-size: 2rem;
		line-height: 2.8rem;
		color: @dark-grey;
		margin-bottom: 0.8rem;
		
		&--white {
			color: white;
		}
		
		&--medium {
			font-family: 'WorkSans-Medium', sans-serif;
		}
		
		&--no-bottom {
			margin-bottom: 0;
		}
	}
	
	&__text {
		font-size: 1.5rem;
		line-height: 2.6rem;
		color: @paragraph;
		margin-bottom: 1.6rem;
	}
	
	&__time {
		font-family: 'WorkSans-Medium', sans-serif;
		font-size: 1.4rem; 
		color: @blue;
		padding: 0.8rem 1.6rem;
		border-radius: 0.4rem;
		background: fade(@blue, 20%);
	}
	
	&__info {
	    display: flex;
		flex-direction: row;
		align-items: center; 
	} 
	
	&__image {
	    height: 22rem;
	    overflow: hidden;
	    
	    img {
		    width: 100%;
		    height: 100%;
		    object-fit: cover;
	    }
	} 
	
	&__date {
		font-family: 'WorkSans-Regular', sans-serif;
		font-size: 1.4rem; 
		color: fade(@dark-grey, 50%);
		margin-bottom: 0.8rem;
	}
	
	&__top {
		background: @blue;
		color: white;
		text-align: center;
		padding: 2.4rem 0;
		
		svg {
			margin-bottom: 0.8rem;
		}
		
		span {
			font-size: 1.4rem;
			opacity: 0.6;
		}
	}
	
	&__price-wrapper {
		border-left: 1px solid #EDEFF1;
		border-right: 1px solid #EDEFF1;
	    padding: 2.4rem 0;
		text-align: center;
		
		span {
			font-family: 'WorkSans-Regular', sans-serif;
			font-size: 1.4rem; 
			line-height: 2.4rem;
			color: @dark-grey;
			opacity: 0.7;
		}
	}
	
	&__description {
		padding: 2.4rem 3.2rem;
		border-left: 1px solid #EDEFF1;
		border-right: 1px solid #EDEFF1;
		border-bottom: 1px solid #EDEFF1;
		
		.button--black {
			padding: 1.2rem 0;
			width: 100%;
			height: 5.6rem;
		}
		
		p {
			margin: 0;
			font-family: 'WorkSans-Regular', sans-serif;
			font-size: 1.4rem; 
			color: @dark-grey;
			opacity: 0.7;
			line-height: 2.4rem;
			margin-bottom: 3.2rem;
		}
		
		ul {
			margin-bottom: 0;
			
			li {
				font-size: 1.4rem;
				padding: 0.4rem 0 0.4rem 3rem;
			}
		}
	}
	
	&__price {
		font-family: 'WorkSans-Medium', sans-serif;
		font-size: 3.2rem; 
		color: @dark-grey;
	}
	
	&--appointment {
		padding: 0;
 		
		.tile__inner {
			background: white;
		} 
		
		&:hover {
			transform: none !important;
		}
	}
	
	&--event {
		padding: 1.6rem;
		
		.tile__inner {
			flex-direction: row;
			align-items: center;
		}
		
		.tile__image {
		    position: absolute;
		    height: 100%;
		    width: 16rem;
		    
		    img {
			    position: relative;
			    width: 100%;
			    height: 100%;
			    object-fit: cover;
		    }
		}
		
		.tile__content {
			padding: 4.8rem;
			margin-left: 16rem; 
		}
		
		.tile__title {
			margin-bottom: 1.6rem;
		} 
		
		.tile__time {
			svg {
				width: 1.3rem;
				margin-right: 1.2rem;
			}
		}
		
		.tile__note {
			font-family: 'WorkSans-Medium', sans-serif;
			margin-left: 1.6rem;
			color: @blue;
		}
		
		.button {
			margin-left: auto;
			margin-right: 4.8rem;
		} 
	}
	
	&:hover {
		transform: translateY(-0.8rem); 
		
		.link {
			color: darken(@blue, 10%);
		}
	}
} 

@media only screen and (max-width: 1199px) {
.tile {
	&--appointment {
		margin-bottom: 2.4rem;
	}
}
}


@media only screen and (max-width: 991px) {
.tile {
	&--category {
		margin-bottom: 2.4rem;
		
		&:nth-child(3),
		&:nth-child(4) {
			margin-bottom: 0;
		}
	}
	
	&--event { 
		padding: 1.6rem;
		
		.tile__content {
			padding: 3.2rem;
		}
		
		.tile__title {
		    font-size: 1.8rem;
		    margin-bottom: 0.8rem;
		}
		
		.button {
			margin-right: 3.2rem;
			white-space: nowrap;
		}
	}
}
}

@media only screen and (max-width: 640px) {
.tile {
	&__content {
		padding: 3.2rem;
	}
	
	&--category {		
		&:nth-child(3) {
			margin-bottom: 2.4rem;
		}
	}
	
	&--event {
		padding: 0.8rem 0;
		
		.tile__inner {
			flex-direction: column;
			align-items: flex-start;
		}
		.tile__image {
		    width: 100%;
		    height: 18rem;
		    position: relative;
		}
		.tile__content {
			width: 100%;
		    margin-left: 0;
		    padding: 3.2rem;
		}
		.button {
		    margin: 0;
		    width: 100%;
		    padding: 0;
		    position: relative;
		}
	}
}
}

/* ********************
Divider
******************** */
.divider {
	position: relative; 
	
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 2.4rem;
		z-index: 3;
	}
	
	&:before {
		position: absolute;
		content:"";
		left: 1.6rem;
		top: 50%;
		transform: translateY(-50%);
		width: ~"calc(100% - 3.2rem)";
		height: 0.1rem;
		background: @dark-grey;
		opacity: 0.2;
		z-index: 1;
	}
	
	&:after {
		position: absolute;
		content:"";
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		width: 6.4rem;
		height: 2.4rem;
		background: white; 
		z-index: 2;
	}
}


/* ********************
Flexibele content - Accordeon
******************** */
.accordeon {
	margin-top: 3rem;
	
	&__wrapper { 
		background: white; 
		padding: 1.9rem 2.4rem; 
		border: 0.1rem solid #DCDCDC; 
		margin-bottom: 1.6rem; 
	}
	
	&__content { 
		display: none; 
		padding-top: 1.6rem; 
		
		p {
			margin-bottom: 0 !important;
		}
	}
	
	&__title { 
		position: relative; 
		font-size: 1.6rem; 
		line-height: 2.4rem; 
		color: @dark-grey; 
		cursor: pointer; 
		transition: all .2s ease-in-out; 
		padding-right: 2.4rem; 
		
		&:hover	{ 
			color: @blue; 
		}
		
		&:before { 
			position: absolute;  
			content:"";
 			background-image: url('@{base-url}/assets/img/accordeon-plus.svg');
		    background-repeat: no-repeat;
		    background-position: center center;
		    background-size: 1rem;
		    width: 1rem;
		    height: 1rem;
			right: 0; 
			top: 50%;
			transform: translateY(-50%);  
			opacity: 0.6; 
			visibility: visible; 
			transition: all .2s ease-in-out; 
		}
		
		&:after { 
			position: absolute;  
			content:"";
 			background-image: url('@{base-url}/assets/img/accordeon-minus.svg');
		    background-repeat: no-repeat;
		    background-position: center center;
		    background-size: 1rem;
		    width: 1rem;
		    height: 1rem;
			right: 0; 
			top: 50%;
			transform: translateY(-50%);  
			opacity: 0; 
			visibility: hidden; 
			transition: all .2s ease-in-out; 
		}
		
		&--no-before {
			&:before { 
				opacity: 0; 
				visibility: hidden; 
			}
			
			&:after { 
				opacity: 0.6; 
				visibility: visible; 
			}
		}
	}
}	 

@media only screen and (max-width: 580px) {
.accordeon {
	&__wrapper { 
		padding: 1.2rem 1.6rem; 
		margin-bottom: 0.8rem; 
	}
}
}

/* ********************
Formulier
******************** */
.wpcf7 {
	margin-top: 5.6rem; 
	
	.wpcf7-response-output {
		font-family: 'WorkSans-Medium', sans-serif;
		font-size: 1.4rem;
		
		&.wpcf7-mail-sent-ok {
			color: @blue;
		}
		
		&.wpcf7-validation-errors {
			color: #af1a2b; 
		}
	}
	
	.screen-reader-response {
		display: none;
	}
	 
}

@media only screen and (max-width: 991px) {
.wpcf7 {
	margin-top: 4rem;
}
}

.wpcf7-form {  
	
	br {
		display: none;
	}
	
	label {
		display: inline-flex;
		flex-direction: column;
		padding: 0.8rem 0;
		    
		span {
			font-family: 'WorkSans-Medium', sans-serif;
			font-size: 1.4rem;
		}
	}
	
	.wpcf7-form-control-wrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0.8rem 0;
		
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		select,
		textarea {
			font-family: 'WorkSans-Regular', sans-serif;
			font-size: 1.4rem; 
			height: 5rem;
			background: white;
			border: 0.1rem solid #DFE0E1;
			padding: 0 2.4rem;
			width: 100%;
			-webkit-appearance: none;
			border-radius: 0;
			
			&:focus {
				outline: none;
			}
			
			&::placeholder {
				color: #A1A1A1;
			}
		} 
		
		.wpcf7-checkbox {
			display: flex;
			flex-direction: column;
			
			.wpcf7-list-item-label {
				font-family: 'WorkSans-Regular', sans-serif;
				margin-left: 1.2rem;
			}
		}
		
		textarea { 
			height: 10rem; 
			padding: 2.4rem;
			resize: none;
			-webkit-appearance: none;
		}
		
		.wpcf7-not-valid-tip {
			font-family: 'WorkSans-Medium', sans-serif;
			font-size: 1.4rem;
			color: #ab0c21;
			line-height: 1;
			margin-top: 0.8rem;
		}
	}
	
	input[type="submit"] {
		-webkit-appearance: none;
		border-radius: 0;
		outline: none;
	}
}

@media only screen and (max-width: 640px) {
.wpcf7-form { 
	.wpcf7-form-control-wrap {
		padding: 0.4rem 0;
	}
}	
}

/* Inschrijven formulier */
#wpcf7-f445-o1 {
	form {
		.wpcf7-form-control-wrap {
			max-width: 50%;
			width: 50%;
		}
	}
}

@media only screen and (max-width: 640px) {
#wpcf7-f445-o1 {
	form {
		.wpcf7-form-control-wrap {
			max-width: 100%;
			width: 100%;
		}
	}
}
}

/* ********************
Usps
******************** */
.usps {
	ol,
	ul {
		display: flex;
		flex-direction: column;
	}
	
	&--row {
		ol,
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			
			li {
				font-family: 'WorkSans-Medium', sans-serif;
				font-size: 1.4rem;
				flex-basis: 25%;
				max-width: 25%;
				color: @blue; 
			}
		}
	}
}

@media only screen and (max-width: 991px) {
.usps {
	&--row {
		ol,
		ul {
			li {
				flex-basis: 33.3333%;
				max-width: 33.3333%;
			}
		}
	}
}
}

@media only screen and (max-width: 640px) {
.usps {
	&--row {
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		
		ol,
		ul {
			flex-direction: row;
			flex-wrap: nowrap;
    
			li {
				flex-basis: 100%;
				max-width: 100%;
				white-space: nowrap;
				padding: 0.4rem 0 0.4rem 3rem;
				margin-right: 1.6rem;
			}
		}
	}
}
}

/* ********************
Column outlined
******************** */
.outlined {
	border: 0.1rem solid fade(@dark-grey, 20%);
	padding: 4rem;
	text-align: left;
	height: 100%;  
	
	&__wrapper {
		margin-top: 3.2rem;
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;

		.col-lg-6 {
			&:nth-child(1) {
				padding-right: 0;
			}
			&:nth-child(2) {
				padding-left :0;
			}
		}
	}
	
	&--left {
		border-right: 0;
	} 
	
	h3 {
		font-family: 'WorkSans-Medium', sans-serif;
		font-size: 2rem;
		margin-bottom: 0.8rem;
		color: @blue;
	}
	
	p {
		font-size: 1.5rem;
	}
}

@media only screen and (max-width: 991px) {
.outlined {
	border: 0;
    padding: 0;
    
	&__wrapper {
		margin-top: 2.4rem;
		    
		.col-lg-6 {
			&:nth-child(1) {
				padding-right: 1.5rem;
				margin-bottom: 2.4rem;
			}
			&:nth-child(2) {
				padding-left: 1.5rem;
			}
		}
	}
	
}
}

/* ********************
Footer
******************** */
.footer {
	&__top {
		background: @blue;
		padding: 5.6rem 0;
		
		.editor {
			p {
				color: white;
				font-size: 1.4rem;
				line-height: 2.4rem;
				opacity: 0.6;
			}
			
			h3 {
				font-size: 1.8rem;
				margin-bottom: 0.8rem;
				color: white;
			}
			
		}
	}
	&__middle {
		padding: 4rem 0;
		
		.mainnav {
			ul {
				li {
					padding: 0 2.4rem;
					
					a {
						text-indent: 0;
						font-family: 'WorkSans-Regular', sans-serif;
						color: @dark-grey;
						font-size: 1.6rem;
						padding: 0;
						line-height: 2.2;
						opacity: 1;
					}
				}
			}
		}
	}
	&__bottom {
		background: @light-grey;
		padding: 2rem 0;
	}
}

@media only screen and (max-width: 991px) {
.footer {
	
	.editor {
		margin-bottom: 2.4rem;
	}
	
	&__top {
		padding: 4rem 0;
	} 
	
	&__middle {
		padding: 2.4rem 0;
	}
}
}

@media only screen and (max-width: 767px) {
.footer {
	&__middle {
		.mainnav {
			> ul {
				> li {
					border-bottom: 0;
					padding: 0; 
				}
			}
		}
	}
}
}

@media only screen and (max-width: 640px) {
.footer {
	&__middle {
		.mainnav {
			> ul {
				> li {
					padding: 0;
					border-bottom: 0;
				}
			}
		}
	}
	
	&__bottom {
		padding: 1.6rem 0;
	}
}
}

.company-info {
	display: flex;
    align-items: flex-end;
	
	&__column {
		flex-basis: 50%;
		max-width: 50%; 
		
		p {
			opacity: 0.8 !important; 
		}
		
		ul {
			margin-bottom: 0; 
			
			li {
				padding: 0;
				
				&:before {
					display: none;
				}
				
				a {
					position: relative;
					color: white;
					font-size: 1.4rem;
					line-height: 2.4rem;
					opacity: 0.8;
					transition: all .2s ease-in-out;
					text-decoration: none;
					
					&:hover {
						opacity: 1;
					} 
				}
				
				&:first-child {
					a {
						padding-left: 2.4rem;
						
						&:before {
							position: absolute;
							content:"";
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							background-image: url('@{base-url}/assets/img/mail-icon-white.svg');
						    background-repeat: no-repeat;
						    background-position: center center;
					        background-size: 13px;
						    width: 1.3rem;
						    height: 1rem;
						}
					}
				}
				
				&:last-child {
					a {
						padding-left: 2.4rem;
						
						&:before {
							position: absolute;
							content:"";
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							background-image: url('@{base-url}/assets/img/phone-icon-white.svg');
						    background-repeat: no-repeat;
						    background-position: center center;
					        background-size: 13px;
						    width: 1.3rem;
						    height: 1.3rem;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 991px) {
.company-info {
	margin-bottom: 0;
}
}

@media only screen and (max-width: 640px) {
.company-info {
	flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0 !important;
    
	&__column {
		flex-basis: 100%;
		max-width: 100%;
		
		&--left {
			margin-bottom: 1.6rem;
		}
	}
}
}

.footernav {
	ul {
		padding: 0; 
		display: flex;
		flex-direction: row;
		margin: 0;
		
		li {
			padding-right: 3.2rem;
			
			a {
				font-size: 1.2rem;
				color: @dark-grey;
				text-decoration: none;
				
				&:hover {
					text-decoration: underline;
				}
			}
			
			&:last-child {
				padding-right: 0;
			}
		}
	}
}

@media only screen and (max-width: 640px) {
.footernav {
	ul {
		flex-direction: column;
	}
}
}

.copyright {
	position: relative;
	text-align: right;
	    
	&__right {
		//position: absolute; 
		//top: 50%;
	    //transform: translateY(-50%);
	    //right: 1.5rem;
		font-size: 1.2rem;
		color: @dark-grey;
		text-decoration: none;
	}
}

@media only screen and (max-width: 640px) {
.copyright { 
    &__right {
	    position: relative;
	    transform: none;
	    top: 0;
	    right: 0;
	    margin-top: 1.6rem;
    }
}
} 

/* ********************
WhatsApp floating icon
******************** */
.whatsapp-floating {
    position: fixed;
	right: 1.6rem;
	bottom: 1.6rem;
	z-index: 9999;
	
	
	&:hover {
		svg {
			animation: whatsapp-floating .3s;
		}
	}
}
 
@keyframes whatsapp-floating {
	0% {
		transform: rotate(0) scale(1);
	}
	25% {
		transform: rotate(-5deg) scale(1.04);

	}
	50% {
		transform: rotate(0deg) scale(1.04);
	}
	75% {
		transform: rotate(5deg) scale(1.04);
	}
	100% {
		transform: rotate(0) scale(1);
	} 
}

/* ********************
Calendly
******************** */
body.page-template-page-calendly & {
	.section--two-col {
		.medium-18 {
			font-size: 2.2rem;
			margin-bottom: 1.6rem;
		}
	}
} 

/* ********************
Modal
******************** */
.modal {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 9999;
    max-height: 90vh; 
    transform: translateY(120%);
    transition: all .3s ease-in-out; 
    
    &__wrapper {
	    height: 100%;
	    overflow: scroll;
		-webkit-overflow-scrolling: touch;
    }
     
    &__title {
	    text-align: center;
	    color: @blue;
	    font-size: 2.2rem;
    }
    
    &__inner {
	    padding: 6.4rem 0;
    }
    
    &__close {
	    position: absolute;
	    width: 6.4rem;
	    height: 6.4rem;
	    background: @blue;
	    border-radius: 100%;
	    left: 50%;
	    top: 0;
	    transform: translate(-50%,-50%);
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    cursor: pointer;
	    transition: all .2s ease-in-out; 
	    
	    svg {
		    position: absolute;
		    z-index: 9999;
		    width: 2.4rem;;
		    height: 2.4rem;
	    }
	    
	    &:hover {
		    background: darken(@blue, 10%);
	    }
    }
    
    &__embed {
	    opacity: 0;
	    visibility: hidden;
	    height: 100%;
	    max-height: 0;
	    transition: all .3s ease-in-out; 
	    transition-delay: .2s;
	    
	    &--active {
		    opacity: 1;
			visibility: visible;
			max-height: 100%;
	    }
    } 
    
    &--active {
	    transform: translateY(0%);
    }
}

@media only screen and (max-width: 767px) {
.modal { 
	
	&__wrapper {
		padding-top: 4rem;
	}
	
    &__close {
	    width: 6.4rem;
	    height: 6.4rem;
	    background: rgba(54, 169, 225, 0.1);
	    border-radius: 0;
	    transform: translate(-50%,0%);
	    
	    svg {
		    path {
			    &:nth-child(1) {
				    fill: @blue;
			    }
		    }
	    }
    } 
}
}

/* ********************
=Vrijblijvend kennismaken
******************** */
body.page-template-page-kennismaken {
	.section--flex-white {
		padding-bottom: 0;
	}
	.calendly-inline-widget {
		margin-bottom: 6.4rem;
	}
}

@media only screen and (max-width: 650px) {
body.page-template-page-kennismaken {
	.section--flex-white {
		padding-bottom: 4rem;
	}
	.calendly-inline-widget {
		margin-bottom: 0;
	}
}

}

