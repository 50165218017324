@base-url: "/wp-content/themes/puur-fit/";

@font-face {
    font-family: 'WorkSans-Light';
    src: url('@{base-url}/static/fonts/WorkSans-Light.eot');
    src: url('@{base-url}/static/fonts/WorkSans-Light.eot?#iefix') format('embedded-opentype'),
         url('@{base-url}/static/fonts/WorkSans-Light.woff') format('woff'),
         url('@{base-url}/static/fonts/WorkSans-Light.woff2') format('woff2'),
         url('@{base-url}/static/fonts/WorkSans-Light.ttf') format('truetype'),
         url('@{base-url}/static/fonts/WorkSans-Light.svg#WorkSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
} 

@font-face {
    font-family: 'WorkSans-Regular';
    src: url('@{base-url}/static/fonts/WorkSans-Regular.eot');
    src: url('@{base-url}/static/fonts/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('@{base-url}/static/fonts/WorkSans-Regular.woff') format('woff'),
         url('@{base-url}/static/fonts/WorkSans-Regular.woff2') format('woff2'),
         url('@{base-url}/static/fonts/WorkSans-Regular.ttf') format('truetype'),
         url('@{base-url}/static/fonts/WorkSans-Regular.svg#WorkSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'WorkSans-Medium';
    src: url('@{base-url}/static/fonts/WorkSans-Medium.eot');
    src: url('@{base-url}/static/fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
         url('@{base-url}/static/fonts/WorkSans-Medium.woff') format('woff'),
         url('@{base-url}/static/fonts/WorkSans-Medium.woff2') format('woff2'),
         url('@{base-url}/static/fonts/WorkSans-Medium.ttf') format('truetype'),
         url('@{base-url}/static/fonts/WorkSans-Medium.svg#WorkSans-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'WorkSans-SemiBold';
    src: url('@{base-url}/static/fonts/WorkSans-SemiBold.eot');
    src: url('@{base-url}/static/fonts/WorkSans-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('@{base-url}/static/fonts/WorkSans-SemiBold.woff') format('woff'),
         url('@{base-url}/static/fonts/WorkSans-SemiBold.woff2') format('woff2'),
         url('@{base-url}/static/fonts/WorkSans-SemiBold.ttf') format('truetype'),
         url('@{base-url}/static/fonts/WorkSans-SemiBold.svg#WorkSans-SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'WorkSans-Bold';
    src: url('@{base-url}/static/fonts/WorkSans-Bold.eot');
    src: url('@{base-url}/static/fonts/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('@{base-url}/static/fonts/WorkSans-Bold.woff') format('woff'),
         url('@{base-url}/static/fonts/WorkSans-Bold.woff2') format('woff2'),
         url('@{base-url}/static/fonts/WorkSans-Bold.ttf') format('truetype'),
         url('@{base-url}/static/fonts/WorkSans-Bold.svg#WorkSans-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}